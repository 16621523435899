import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Globals } from '../globals';
import { ProductService } from '../_services/product.service';
import { CartService } from '../_services/cart.service';
import { Product } from '../_models/product';
declare let fbq: Function;

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    private productService: ProductService,
    public cartService: CartService,
  ) {}

  isUpdating = false;
  product: Product;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params) => {
        const productId = parseInt(params.get('id'));
        if (!this.globals.isNullOrEmpty(productId)) {
          this.productService.getProductById(productId).subscribe({
            next: (p) => {
              this.product = p;
              this.productService.getProductContents(p.Id).then((res) => (this.product.Content = res));
              fbq('track', 'ViewContent', {
                content_ids: [productId],
                content_name: this.product.Name,
                value: this.product.Price,
                content_type: 'product',
                currency: this.globals.Store.Currency,
              });
            },
          });
        }
      },
    });
  }

  addItem(product: Product) {
    fbq('track', 'AddToCart', {
      content_ids: [this.product.Id],
      content_name: this.product.Name,
      value: this.product.Price,
      content_type: 'product',
    });
    this.isUpdating = true;
    return this.cartService.addToCart(product).finally(() => {
      this.isUpdating = false;
    });
  }

  buyNow(product: Product) {
    this.addItem(product).then((response) => {
      this.globals.goToUrl(this.router, '/cart');
    });
  }
}
