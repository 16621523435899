import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Globals } from '../globals';
import { Product } from '../_models/product';
import { ProductService } from '../_services/product.service';
import { CartService } from '../_services/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private productService: ProductService,
    public cartService: CartService,
  ) {}

  products: Product[] = [];
  isUpdating = false;
  isLoaded = false;

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          // const page = params.get('page');
          const cat = params.get('category');
          const entryType = params.get('entryType');
          console.log(entryType, this.globals.entryType);
          if (!entryType && this.globals.entryType) {
            this.router.navigate(['catalog', this.globals.entryType]);
          }

          if (!entryType && !this.globals.entryType) {
            this.router.navigate(['catalog', 'LOCAL']);
          }

          if (entryType && !this.globals.entryType) {
            this.globals.entryType = entryType;
            this.router.navigate(['catalog', this.globals.entryType]);
          }

          if (entryType && this.globals.entryType && (entryType != this.globals.entryType)) {
            this.globals.entryType = entryType;
            this.router.navigate(['catalog', this.globals.entryType]);
          }
          
          console.log('params: ', params);
          // if (page && page !== 'catalog') {
          //   this.globals.goToUrl(this.router, 'catalog');
          // }

          if (cat && cat === 'featured') {
            this.globals.Store.SelectedCategory = 12;
          }

          if (this.authService.isLogged()) {
            this.refresh();
          } else {
            console.log('Wait for awhile to re-authenticate');
            setTimeout(() => {
              this.refresh();
            }, 5000);
          }

          return Promise.resolve();
        }),
      )
      .subscribe();
  }

  refresh() {
    const category = this.globals.Store.SelectedCategory;
    // if (this.productService.cache.category === category) {
    //   this.products = this.productService.cache.products;
    //   this.isLoaded = true;
    //   return;
    // }
    console.log('Refresh catalog');
    this.productService.getProducts(category, this.globals.entryType).subscribe(
      (res) => {
        this.productService.cache.category = category;
        this.productService.cache.products = res;
        this.products = res;
        this.isLoaded = true;
      },
      (err) => {
        console.error(err);
        this.snackBar.open('Sorry, your request cannot be process at the moment', 'Dismiss', {
          duration: 2000,
        });
        this.isLoaded = true;
      },
    );
  }

  addItem(product: Product) {
    this.isUpdating = true;
    this.cartService.addToCart(product).finally(() => {
      this.isUpdating = false;
    });
  }

  changeCategory(category: number) {
    this.globals.Store.SelectedCategory = category;
    this.refresh();
  }

  showProduct(productId: number) {
    this.globals.goToUrl(this.router, 'products/' + productId);
  }
}
