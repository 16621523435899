import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Customer } from './_models/customer';
import { Observable, from, defer, Subject, BehaviorSubject, of } from 'rxjs';
import { reduce, switchMap, tap } from 'rxjs/operators';
import { Address } from './_models/address';
import { CartItem } from './_models/cart-item';
import { AdditionalCharge } from './_models/additional-charge';

@Injectable({
  providedIn: 'root',
})
export class Globals {
  public entryType = '';
  public StaticOptions = {
    Payment: [
      { id: 1, name: 'Debit/Credit' },
      { id: 2, name: 'Cash on Delivery' },
      { id: 3, name: 'Online Payment' },
      { id: 4, name: 'Paypal' },
      { id: 6, name: 'EBank' },
    ],
    Delivery: [
      { id: 1, name: 'Ship to my Address' },
      { id: 2, name: 'Pickup at UNO Main Office' },
    ],
    PickupLocations: [
      { id: 1, name: 'UNO West Insula - Quezon City' },
      { id: 2, name: 'UNO Main Office - Cebu' },
      { id: 3, name: 'UNO Main Office - Davao' },
      { id: 4, name: 'UNO Main Office - CDO' },
      { id: 5, name: 'UNO Main Office - Calamba' },
      { id: 6, name: 'UNO Main Office - Baguio' },
      { id: 7, name: 'UNO Main Office - General Santos' },
    ],
    Category: [
      { id: 0, name: 'ALL' },
      { id: 1, name: 'Accessories' },
      { id: 3, name: 'Food and Beverages' },
      { id: 5, name: 'Oral and Skin Care' },
      { id: 6, name: 'Others' },
      { id: 7, name: 'Promotional Items' },
      { id: 9, name: 'Value-added Services' },
      { id: 10, name: 'Vitamins and Supplements' },
      { id: 11, name: 'UNO Cafe Products' },
      { id: 12, name: 'Featured Products' },
    ],
  };
  public Store = {
    Name: '',
    VendorId: '',
    VendorCountryCode: '',
    AnalyticsId: '',
    Customer: new Customer(),
    SelectedCategory: 0,
    SearchText: '',
    ProductsImageUrl: '',
    RecaptchaKey: '',
    Currency: '₱',
    isCheckedOut: false,
    VendorConfig: {
      ProductListFormat: 'grid',
      HeaderBackgroundColor: '#d9d9d9',
      FooterText: '© Package Store 2023. All Rights Reserved',
      HeaderForegroundColor: '#F1F1F1',
      HeaderLogo: 'assets/img/logo.jpg',
      ButtonBackgroundColor: '#f44336',
      ButtonForegroundColor: '#ffffff',
      PriceColor: '#f57224',
      EnableSlider: false,
      EnableSalesPopup: false,
      SlideTimeout: 3000,
      Slides: [],
      SocialMediaLinks: {
        Facebook: '',
        Twitter: '',
        Youtube: '',
        Instagram: '',
      },
    },
  };

  public cartItemsCount = new BehaviorSubject<boolean>(false);
  public cartItemsCountObserver: Observable<boolean>;

  constructor() {
    this.cartItemsCount.subscribe((result) => {
      if (result) {
        setTimeout(() => {
          this.cartItemsCount.next(false);
        }, 1500);
      }
    });
  }

  public buildImageUrl(imageUrl: string) {
    if (imageUrl.startsWith('http')) {
      return imageUrl;
    }

    if (imageUrl.startsWith('/')) {
      return this.Store.ProductsImageUrl + imageUrl.substring(1);
    }
    return this.Store.ProductsImageUrl + imageUrl;
  }

  public cartItemsQuantity() {
    return this.Store.Customer.Cart.Items.reduce((total, item) => item.Quantity, 0);
    // return _.sumBy(this.Store.Customer.Cart.Items, (o) => o.Quantity);
  }

  public getCurrentCategory() {
    const item = _.find(this.StaticOptions.Category, (o) => o.id === this.Store.SelectedCategory);
    return item ? item.name : '';
  }

  public getTotalAmount(): number {
    return this.Store.Customer.Cart.Items.reduce((total, item) => {
      total += item.Product.Price * item.Quantity;
      return total;
    }, 0);
    // return _.sumBy(this.Store.Customer.Cart.Items, (o) => o.Product.Price * o.Quantity);
  }

  public getCartTotalAmount(): number {
    return this.Store.Customer.Cart.Items.reduce((total: number, value: CartItem, index: number) => {
      total += value.Product.Price * value.Quantity;
      return total;
    }, 0);
  }

  public getGrandTotal(): number {
    return this.getCartTotalAmount() + this.Store.Customer.Cart.ShippingFee + this.getTotalAdditionalCharges();
  }

  public getTotalAdditionalCharges(): number {
    if (this.Store.Customer.Cart.OtherCharges) {
      return this.Store.Customer.Cart.OtherCharges.reduce((total: number, otherCharge: AdditionalCharge) => {
        total += otherCharge.Amount;

        return total;
      }, 0);
    }
    return 0;
  }

  public getTotalWeight() {
    const sum = this.Store.Customer.Cart.Items.reduce((total, item) => {
      total += (item.Product.Weight * item.Quantity);
      return total;
    }, 0);
    if(sum < 1) {
      return 1;
    }
    return Math.round(sum);
  }

  public goToUrl(router, page) {
    router.navigate(['/' + page]);
  }

  public goToUrlEbank(router, page, payment) {
    router.navigate([this.Store.Name + '/' + page + '/' + payment]);
  }

  public isCartEmpty() {
    return !(this.Store.Customer.Cart && this.Store.Customer.Cart.Items.length > 0);
  }

  public isNullOrEmpty(value): boolean {
    return value == null || !value;
  }

  public clearForm() {
    this.Store.isCheckedOut = false;
    this.Store.Customer.Cart.Billing = new Address();
    this.Store.Customer.Cart.Billing.Country = '';
    this.Store.Customer.Cart.Billing.CountryCode = '';
    this.Store.Customer.Cart.Billing.State = '';
    this.Store.Customer.Cart.Billing.StateId = '';
    this.Store.Customer.Cart.Billing.City = '';
    this.Store.Customer.Cart.Billing.CityId = '';
    this.Store.Customer.Cart.Shipping = new Address();
    this.Store.Customer.Cart.Shipping.Country = '';
    this.Store.Customer.Cart.Shipping.CountryCode = '';
    this.Store.Customer.Cart.Shipping.State = '';
    this.Store.Customer.Cart.Shipping.StateId = '';
    this.Store.Customer.Cart.Shipping.City = '';
    this.Store.Customer.Cart.Shipping.CityId = '';
    this.Store.Customer.Cart.PaymentMethod = 1;
    this.Store.Customer.Cart.DeliveryMethod = 1;
    this.Store.Customer.Cart.PickupLocation = 0;
    this.Store.Customer.Cart.ShippingFee = 0;
    this.Store.Customer.Cart.TotalAmount = 0;
    this.Store.Customer.Cart.TotalDiscount = 0;
    this.Store.Customer.Cart.TotalPoints = 0;
    this.Store.Customer.Cart.UseSameAddress = true;
    this.Store.Customer.Cart.SponsorId = '';
  }

  public lightOrDark(color) {
    let r, g, b, hsp;

    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    if (hsp > 127.5) {
      return 'light';
    } else {
      return 'dark';
    }
  }
}
