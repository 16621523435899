import { Country } from './../_models/country';
import { Courier } from './../_models/courier';
import { State } from './../_models/state';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Tools } from '../_shared/tools';
import { Globals } from '../globals';
import { Price } from '../_models/price.js';
import { City } from '../_models/city.js';

@Injectable()
export class RegionalService {
  constructor(private http: HttpClient, private auth: AuthService, public globals: Globals, public tools: Tools) {}

  getCountries() {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/countries`;
    return this.http.get<Country[]>(requestUrl, options);
  }

  getCountryByCode(countryCode: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/countries/ByCode/${countryCode}`;
    return this.http.get<Country>(requestUrl, options);
  }

  getCourierByCountry(countryId: number) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/couriers/ByCountry/${countryId}`;
    return this.http.get<Courier[]>(requestUrl, options);
  }

  getCourierByCountryCode(countryCode: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/couriers/ByCountryCode/${countryCode}`;
    return this.http.get<Courier[]>(requestUrl, options);
  }

  getStatesByCourier(id: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    // const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Basic NjQwOTY6dW5vcHJlbWllcjEyMw==' });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/states/ByCourier/${id}`;
    return this.http.get<State[]>(requestUrl, options);
  }

  getCitiesByState(stateId: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/cities/ByState/${stateId}`;
    return this.http.get<City[]>(requestUrl, options);
  }

  pricesByWeightAndCity(weight: number, city: string) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.getToken()}` });
    // const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Basic NjQwOTY6dW5vcHJlbWllcjEyMw==' });
    const options = { headers: header };
    const requestUrl = `${environment.regional2}/prices/ByWeightAndCity/${weight}/${city}`;
    return this.http.get<Price>(requestUrl, options);
  }
}
