import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProductService } from '../_services/product.service';
import ProductSalesPopUpData from '../_models/product-sales-popup-data';
import { from, interval } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-sales-pop-up',
  templateUrl: './sales-pop-up.component.html',
  styleUrls: ['./sales-pop-up.component.scss'],
})
export class SalesPopUpComponent implements OnInit {
  public currentData: ProductSalesPopUpData = new ProductSalesPopUpData();
  public dateString = '';
  public disappear = true;
  public loading = true;
  public productSalesPopUpData: ProductSalesPopUpData[] = [];

  constructor(private productService: ProductService, public globals: Globals, private router: Router) {}

  public ngOnInit(): void {
    this.loadData();
  }

  public loadData() {
    this.productService.getProductSalesPopUpData().subscribe(
      (resp) => {
        this.productSalesPopUpData = resp;
        this.startTimer();
      },
      (err) => {
        console.error(err);
      },
    );
  }

  public openProduct(productId: number) {
    this.globals.goToUrl(this.router, `/products/${productId}`);
  }

  public startTimer() {
    const ob = interval(10000)
      .pipe(
        map((index) => {
          if (index === 0) {
            this.loading = false;
          }
          if (index >= this.productSalesPopUpData.length) {
            ob.unsubscribe();
            this.loadData();
          }
          return this.productSalesPopUpData[index];
        }),
      )
      .subscribe((data) => {
        this.disappear = false;
        this.currentData = data;
        this.dateString = moment(this.currentData.DateSold).fromNow();

        setTimeout(() => {
          this.disappear = true;
        }, 5000);
      });
  }

  public getImageUrl() {
    if (this.currentData.ImageUrl.startsWith('http')) {
      return this.currentData.ImageUrl;
    } else if (this.currentData.ImageUrl.startsWith('/')) {
      return `https://images.unomain.com/coxpchxeixfgmngjgrvov2/upl${this.currentData.ImageUrl}`;
    } else {
      return `https://images.unomain.com/coxpchxeixfgmngjgrvov2/upl/${this.currentData.ImageUrl}`;
    }
  }
}
