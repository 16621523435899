<!-- <div class="topHeader__container">
  <div class="topHeaderContent__container">
    <div class="topHeaderAccreditation__container">
      <p class="topHeaderAccreditation__text">
        Accredited by
      </p>
      <img src="/assets/img/uno-logo.png" alt="UNO logo" class="topHeaderAccreditation__image" />
    </div>
  </div>
  <div class="topHeaderContent__container">
    <a class="topHeaderContent__iconLink topHeaderContent__iconLinkFacebook"
      *ngIf="vendorConfig.SocialMediaLinks && vendorConfig.SocialMediaLinks.Facebook"
      [href]="vendorConfig.SocialMediaLinks.Facebook" target="_blank">
      <fa-icon [icon]="['fab', 'facebook']" size="sm" [fixedWidth]="true" class="topHeaderContent__icon"></fa-icon>
    </a>
    <a class="topHeaderContent__iconLink topHeaderContent__iconLinkYoutube"
      *ngIf="vendorConfig.SocialMediaLinks && vendorConfig.SocialMediaLinks.Youtube"
      [href]="vendorConfig.SocialMediaLinks.Youtube" target="_blank">
      <fa-icon [icon]="['fab', 'youtube']" size="sm" [fixedWidth]="true" class="topHeaderContent__icon"></fa-icon>
    </a>
    <a class="topHeaderContent__iconLink topHeaderContent__iconLinkTwitter"
      *ngIf="vendorConfig.SocialMediaLinks && vendorConfig.SocialMediaLinks.Twitter"
      [href]="vendorConfig.SocialMediaLinks.Twitter" target="_blank">
      <fa-icon [icon]="['fab', 'twitter']" size="sm" [fixedWidth]="true" class="topHeaderContent__icon"></fa-icon>
    </a>
    <a class="topHeaderContent__iconLink topHeaderContent__iconLinkInstagram"
      *ngIf="vendorConfig.SocialMediaLinks && vendorConfig.SocialMediaLinks.Instagram"
      [href]="vendorConfig.SocialMediaLinks.Instagram" target="_blank">
      <fa-icon [icon]="['fab', 'instagram']" size="sm" [fixedWidth]="true" class="topHeaderContent__icon"></fa-icon>
    </a>
  </div>
</div> -->
<div class="header-container">
  <!-- <nav *ngIf="this.globals.Store.Name === ''" class="header navbar navbar-expand-md container">
    <div class="logo-box" style="margin: auto;">
      <img src="/assets/img/uno-logo.png" style="height:4em; position: relative;">
    </div>
  </nav> -->
  <nav class="header navbar navbar-expand-md container">
    <div class="logo-box" [routerLink]="['catalog']">
      <img src="/assets/img/uno-logo.png" style="height:4em; position: relative;">
    </div>
    <div class="collapse navbar-collapse">
      <div class="input-group">
        <div class="input-group-prepend">
          <button mat-button [matMenuTriggerFor]="menu"
            class="input-group-text">{{globals.getCurrentCategory()}}</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let item of globals.StaticOptions.Category"
              (click)="changeCategory(item.id)">{{item.name}}</button>
          </mat-menu>
        </div>
        <input type="text" class="form-control" aria-label="Search for products"
          [(ngModel)]="this.globals.Store.SearchText" placeholder="Search for products">
        <div class="input-group-append">
          <span class="input-group-text search-btn">
            <button mat-button>
              <mat-icon>search</mat-icon>
            </button>
          </span>
        </div>
      </div>
      <div class="cart-btn-container">
        <button class="cart-btn" mat-button mat-icon-button routerLink="/cart">
          <mat-icon matBadge="{{globals.cartItemsQuantity()}}" [ngClass]="animate ? 'swingItem' : ''"
            matBadgePosition="above after" matBadgeColor="warn" [matBadgeHidden]="globals.isCartEmpty()">
            shopping_cart</mat-icon>
        </button>
      </div>
    </div>
    <div class="mr-auto action-panel"></div>
    <div class="action-panel">
      <button mat-button mat-icon-button (click)="toggleSearchPanel()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="action-panel">
      <div class="drawer cart-btn-container">
        <button class="cart-btn" mat-button mat-icon-button routerLink="/cart">
          <mat-icon matBadge="{{globals.cartItemsQuantity()}}" [ngClass]="animate ? 'swingItem' : ''"
            matBadgePosition="above after" matBadgeColor="warn" [matBadgeHidden]="globals.isCartEmpty()">
            shopping_cart</mat-icon>
        </button>
      </div>
    </div>
  </nav>
</div>
<div class="search-panel action-panel" *ngIf="showSearchMini">
  <div class="search-box">
    <div class="searchbar">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputCategoryGroupSelect">Category</label>
        </div>
        <select class="custom-select category-select" id="inputCategoryGroupSelect"
          [(ngModel)]="this.globals.Store.SelectedCategory" #t (change)="changeCategory(t.value)">
          <option *ngFor="let item of globals.StaticOptions.Category" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="input-group" style="margin-top: 5px;">
        <input type="text" class="form-control" aria-label="Search for products"
          [(ngModel)]="this.globals.Store.SearchText" placeholder="Search for products">
        <div class="input-group-append">
          <span class="input-group-text search-btn">
            <button mat-button (click)="toggleSearchPanel()">
              <mat-icon>search</mat-icon>
              Find
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>